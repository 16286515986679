import { createTheme } from "@mui/material"

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#dadada",
    },
    secondary: {
      main: "#a5e2ff",
    },
    background: {
      default: "#000000",
      paper: "#363737",
    },
    text: {
      primary: "#d6d6d6",
      secondary: "#a5e2ff",
      disabled: "#ff1100",
      hint: "#00e5ff",
    },
    info: {
      main: "#00dfff",
    },
    divider: "#ffffff",
    error: {
      main: "#ff1200",
    },
    warning: {
      main: "#ff9800",
    },
    success: {
      main: "#ffff00",
    },
  },
  typography: {
    fontFamily: '"Gothic A1", "Arial", sans-serif',
    fontSize: 13,
    fontWeightLight: 100,
    fontWeightRegular: 100,
    fontWeightMedium: 100,
    fontWeightBold: 100,
    htmlFontSize: 16,
    h1: {
      fontSize: "5.4rem",
      fontWeight: 100,
      lineHeight: 0.96,
    },
    subtitle: {
      fontSize: "1rem",
      lineHeight: 0,
    },
    h2: {
      fontSize: "2.5rem",
      lineHeight: 3,
    },
    h3: {
      fontSize: "1.8rem",
      lineHeight: 1.04,
    },
    h4: {
      fontSize: "1.6rem",
      fontWeight: 100,
    },
    button: {
      lineHeight: 0.65,
      fontSize: "0.8rem",
    },
    caption: {
      fontSize: "0.8rem",
      lineHeight: 1.22,
    },
    h5: {
      lineHeight: 1.3,
    },
  },
  shape: {
    borderRadius: 2,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
          * {
            box-sizing: border-box;
          }
          body {
            margin: 0; // You can set global body styles here
            padding: 0;
            // ... any other global styles
          }
        `,
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          margin: "0 0 0 0",
          padding: "0 0 0 0",
          boxSizing: "border-box",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          width: "100%",
          margin: "0 0 0 0",
          padding: "0 0 0 0",
          boxSizing: "border-box",
        },
        defaultProps: {
          variant: "elevation",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          "&:hover": {
            color: theme => theme.palette.secondary.main, // Use .main to access the color value
          },
        },
      },
      defaultProps: {
        underline: "none",
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
          border: 0,
          borderRadius: 3,
          boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
          color: "white",
          height: 48,
          padding: "0 30px",
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 46,
          height: 27,
          padding: 0,
          margin: 8,
        },
        switchBase: {
          padding: 1,
          "&$checked, &$colorPrimary$checked, &$colorSecondary$checked": {
            transform: "translateX(16px)",
            color: "#fff",
            "& + $track": {
              opacity: 1,
              border: "none",
            },
          },
        },
        thumb: {
          width: 24,
          height: 24,
        },
        track: {
          borderRadius: 13,
          border: "1px solid #bdbdbd",
          backgroundColor: "#fafafa",
          opacity: 1,
          transition:
            "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
      },
    },
  },
  props: {
    MuiTooltip: {
      arrow: true,
    },
    MuiButton: {
      size: "small",
    },
    MuiButtonGroup: {
      size: "small",
    },
    MuiCheckbox: {
      size: "small",
    },
    MuiFab: {
      size: "small",
    },
    MuiFormControl: {
      margin: "dense",
      size: "small",
    },
    MuiFormHelperText: {
      margin: "dense",
    },
    MuiIconButton: {
      size: "small",
    },
    MuiInputBase: {
      margin: "dense",
    },
    MuiInputLabel: {
      margin: "dense",
    },
    MuiRadio: {
      size: "small",
    },
    MuiSwitch: {
      size: "small",
    },
    MuiTextField: {
      margin: "dense",
      size: "small",
    },
    MuiList: {
      dense: true,
    },
    MuiMenuItem: {
      dense: true,
    },
    MuiTable: {
      size: "small",
    },
  },
  spacing: 5,
  overrides: {
    MuiAppBar: {
      colorInherit: {
        backgroundColor: "#689f38",
        color: "#fff",
      },
    },
  },
})

export default theme
